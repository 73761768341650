/*import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import 'select2';*/

import Alpine from 'alpinejs';
window.Alpine = Alpine;
document.addEventListener('DOMContentLoaded', () => {
  Alpine.start();
});


// Sticky Navbar Functionality
window.onscroll = function () {
  const header = document.querySelector('.fixed-header');
  if (window.pageYOffset >= 100) {
    header.classList.add('sticky-navbar');
  } else {
    header.classList.remove('sticky-navbar');
  }
};
